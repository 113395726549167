<template>
  <div class="portal-layout">
    <div class="portal-layout__nav">
      <a href="https://ourforest.io"><img src="../assets/logo.svg" alt="OurForest Logo"></a>
    </div>
    <router-view/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Analytics from '@/helpers/analytics';

@Options({
  beforeCreate() {
    Analytics.setup();
  }
})

export default class PortalLayout extends Vue {

}

</script>


<style lang="scss">
.portal-layout {
  background: #DAF4BF;
  min-height: 100vh;
  height: 100%;
  padding: 16px;
  background-image: url('../assets/background-grass.svg');
  background-size: contain;
  position: relative;
}
.portal-layout__nav {
  padding: 16px;
  text-align: center;
}
.portal-layout__nav a {
  text-decoration: none;
}

</style>
