
import { Options, Vue } from 'vue-class-component';
import Analytics from '@/helpers/analytics';

@Options({
  beforeCreate() {
    Analytics.setup();
  }
})

export default class PortalLayout extends Vue {

}

